import React, { useState, useEffect } from 'react';
import Input from '../../atoms/Input/Input';
import { db } from '../../../firebase';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import Modal from '../Modal/Modal';
import './Form.css';

function Form() {
  const [formData, setFormData] = useState({
    nome: '',
    instituicao: '',
    oficina: '',
    grupo: ''
  });

  const [errors, setErrors] = useState({});
  const [availableGroups, setAvailableGroups] = useState([]); // Estado para armazenar grupos com vagas
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    if (formData.oficina) {
      fetchGroupOptions(); // Busca os grupos com base na oficina selecionada
    }
  }, [formData.oficina]);

  // Função para atualizar o estado com as mudanças dos campos do formulário
  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verifica se todos os campos obrigatórios estão preenchidos
    const newErrors = {};
    if (!formData.nome) newErrors.nome = 'O nome é obrigatório';
    if (!formData.instituicao) newErrors.instituicao = 'Este campo é obrigatório';
    if (!formData.oficina) newErrors.oficina = 'Selecione uma oficina';
    if (!formData.grupo) newErrors.grupo = 'Selecione um grupo';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const querySnapshot = await getDocs(collection(db, formData.grupo));
      const documentCount = querySnapshot.size;

      if (documentCount < 23) {
        await addDoc(collection(db, formData.grupo), formData);
        setIsSuccessModalOpen(true);
      } else {
        setIsErrorModalOpen(true);
      }

    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
      setIsErrorModalOpen(true);
    }
  };

  // Função para buscar as opções de grupo com base na oficina
  const fetchGroupOptions = async () => {
    let groups = [];

    if (formData.oficina === 'Oficinas de Arte e Educação como metodologia do trabalho com Juventudes') {
      groups = [
        'Grupo 01 - A arte negra do educar',
        'Grupo 02 - Corpo e Memória: Urgências e resistências como processo formativo'
      ];
    } else if (formData.oficina === 'Oficinas de Educomunicação como metodologia do trabalho com Juventudes') {
      groups = [
        'Grupo 03 - A prática educomunicativa, suas linguagens e abordagens metodológicas',
        'Grupo 04 - Teorizando o fazer educomunicativo'
      ];
    }

    const availableGroups = [];

    // Verifica se cada grupo tem menos de 23 inscritos
    for (const group of groups) {
      const querySnapshot = await getDocs(collection(db, group));
      const documentCount = querySnapshot.size;

      if (documentCount < 23) {
        availableGroups.push(group); // Adiciona o grupo à lista se tiver menos de 23 pessoas
      }
    }

    setAvailableGroups(availableGroups); // Atualiza o estado com os grupos disponíveis
  };

  // Função para recarregar a página
  const handleCloseModal = () => {
    window.location.reload();
  };

  return (
    <div className='formulario-capacitacao'>
      <form onSubmit={handleSubmit}>
        
        {/* Campo para nome */}
        <label>Nome completo:</label>
        <Input
          placeholder="João Gabriel Santos de Jesus"
          value={formData.nome}
          onChange={handleChange('nome')}
        />
        {errors.nome && <p className="error">{errors.nome}</p>}

        {/* Campo para instituicao */}
        <label>Instituição:</label>
        <Input
          placeholder="Corra pro Abraço"
          value={formData.instituicao}
          onChange={handleChange('instituicao')}
        />
        {errors.instituicao && <p className="error">{errors.instituicao}</p>}

        {/* Select para Oficina */}
        <label>Selecione uma Oficina</label>
        <select
          value={formData.oficina}
          onChange={handleChange('oficina')}
        >
          <option value="" disabled>Selecione uma Oficina:</option>
          <option value="Oficinas de Arte e Educação como metodologia do trabalho com Juventudes">
            Oficinas de Arte e Educação como metodologia do trabalho com Juventudes
          </option>
          <option value="Oficinas de Educomunicação como metodologia do trabalho com Juventudes">
            Oficinas de Educomunicação como metodologia do trabalho com Juventudes
          </option>
        </select>
        {errors.oficina && <p className="error">{errors.oficina}</p>}

        {/* Select para Grupo (filtrado com base na oficina) */}
        <label>Selecione o Grupo temático:</label>
        <select
          value={formData.grupo}
          onChange={handleChange('grupo')}
        >
          <option value="" disabled>Selecione um grupo</option>
          {availableGroups.map((group, index) => (
            <option key={index} value={group}>
              {group}
            </option>
          ))}
        </select>
        {errors.grupo && <p className="error">{errors.grupo}</p>}

        <button type="submit">Enviar</button>
      </form>

      {/* Modal de sucesso */}
      <Modal
        isOpen={isSuccessModalOpen}
        message="Formulário enviado com sucesso!"
        onClose={handleCloseModal}
      />

      {/* Modal de erro */}
      <Modal
        isOpen={isErrorModalOpen}
        message="O limite de inscrições neste grupo foi atingido."
        onClose={handleCloseModal}
      />
    </div>
  );
}

export default Form;
