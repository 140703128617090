import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCaBKyJnkQuzPFToea42TeDlHFD56U0c3Q",
    authDomain: "formulario-capacitacao.firebaseapp.com",
    projectId: "formulario-capacitacao",
    storageBucket: "formulario-capacitacao.appspot.com",
    messagingSenderId: "1040647427751",
    appId: "1:1040647427751:web:b8bba18903124adc472d82"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
