import React, { useState } from 'react';
import './App.css';
import Form from './components/organisms/Form/Form';

function App() {
  const [showForm, setShowForm] = useState(false); // Controla a exibição da tela de introdução ou do formulário

  const handleContinue = () => {
    setShowForm(true); // Mostra o formulário ao clicar no botão
  };

  return (
    <div className="App">
      {showForm ? (
        <Form />  // Mostra o formulário se showForm for verdadeiro
      ) : (
        <div className="intro-screen">
          <h1>Corra pro Abraço como Instrumento de garantia e acesso a direitos para Juventudes Vulnerabilizadas</h1>
        
          <span><strong>Inscrições encerradas.</strong></span>
        </div>
      )}
    </div>
  );
}

export default App;
